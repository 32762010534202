import { httpClient, cachedHttpClient } from './httpClient';
import { randomAnonymous } from './api-utils.js';

const END_POINT = '/feedforwards';
const END_POINT_ANALYZE = '/analyze';

const getFeedForward = (feedforward_id) => httpClient.get(`${END_POINT}/${feedforward_id}`);

const closeFeedForward = (feedforward_id) => httpClient.put(`${END_POINT}/${feedforward_id}/close`);

const taskFeedForward = (feedforward_id) => httpClient.put(`${END_POINT}/${feedforward_id}/tasked`);

const openFeedForward = (feedforward_id) => httpClient.put(`${END_POINT}/${feedforward_id}/open`);

const hideFeedForward = (feedforward_id) => httpClient.put(`${END_POINT}/${feedforward_id}/hide`);

const searchFeedForward = (surveyId, entityIds, query) => cachedHttpClient.get(`${END_POINT_ANALYZE}/${surveyId}/feedforwards/search?entityIds=${entityIds}&q=${query}`);

const submitFeedForwardFeedback = (surveyId, summaryId, vote, userInput) => httpClient.post(`${END_POINT_ANALYZE}/${surveyId}/feedback`, {
      summaryId,
      vote,
      userInput,
    });

const addFeedForwardReact = (feedforward_id, reaction_type) => httpClient.post(`${END_POINT}/${feedforward_id}/reactions/${reaction_type}`);

const removeFeedForwardReact = (feedforward_id, reaction_type) => httpClient.delete(`${END_POINT}/${feedforward_id}/reactions/${reaction_type}`);

const getQueriedFeedForwardResponses = (surveyId, entityTags, questionIds) => {
    return cachedHttpClient.post(`/feedforwardanalysis/query`, {
      surveyId,
      entityTags,
      questionIds,
    }, {
      timeout: 600000,
      cache: {
        methods: ['post']
      }
    });
  };

const getQuestions = (surveyId) => cachedHttpClient.get(`/feedforwardanalysis/questions/${surveyId}`);
const getEntities = (surveyId) => cachedHttpClient.get(`/feedforwardanalysis/entities/${surveyId}`);
const getSurveys = () => cachedHttpClient.get(`/analyze/surveys`);

const getFeedforwards = (surveyId, entityIds) => cachedHttpClient.get(`${END_POINT_ANALYZE}/${surveyId}/feedforwards?entityIds=${entityIds}`)
        .then(response => (response?.data?.forEach(q => q.comments && (q.comments = q.comments?.sort(randomAnonymous)) ), response));
const getSummaries = (surveyId, entityIds, language) => cachedHttpClient.get(`${END_POINT_ANALYZE}/${surveyId}/summaries?entityIds=${entityIds}&lang=${language}`);
const downloadFeedforwards = (surveyId, entityIds) => cachedHttpClient.get(`${END_POINT_ANALYZE}/${surveyId}/feedforwards/file?entityIds=${entityIds}`, {
    responseType: 'blob'
  });


const getAiResponse = (promptRequest, messageRequest) => httpClient.post(`/feedforwardanalysis/analysis`, {
    prompt: promptRequest,
    message: messageRequest,
  }, {
    timeout: 1800000
  });

const saveInfo = (promptRequest, messageRequest, aiResponse, listOfFeedForwards, responseRating) => httpClient.post(`/feedforwardanalysis/response`, {
  prompt: promptRequest,
  message: messageRequest,
  response: aiResponse,
  feedForwards: listOfFeedForwards,
  rating: responseRating
});

export {
    addFeedForwardReact,
    removeFeedForwardReact,
    getFeedForward,
    closeFeedForward,
    openFeedForward,
    taskFeedForward,
    hideFeedForward,
    getQueriedFeedForwardResponses,
    getEntities,
    getQuestions,
    getSurveys,
    getFeedforwards,
    getSummaries,
    downloadFeedforwards,
    getAiResponse,
    saveInfo,
    searchFeedForward,
    submitFeedForwardFeedback,
}
