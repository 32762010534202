import { httpClient } from './httpClient';
import { randomAnonymous } from './api-utils.js';

const END_POINT = '/discussions';


const getAllDiscussions = () => httpClient.get(END_POINT);

const getStatistics = (discussion_id) => httpClient.get(`${END_POINT}/${discussion_id}/statistics`)

// you can pass arguments to use as request parameters/data
const getDiscussion = (discussion_id) => httpClient.get(`${END_POINT}/${discussion_id}`);

const getAllQuestions = (discussion_id) => httpClient.get(`${END_POINT}/${discussion_id}/questions`)

const reorderQuestions = (discussion_id, questionIds) => httpClient.put(`${END_POINT}/${discussion_id}/questions/updateOrder`, questionIds)

const resetQuestionOrder = (discussion_id) => httpClient.put(`${END_POINT}/${discussion_id}/questions/restoreOrder`)

const getTasks = (discussion_id, question_id) => httpClient.get(`${END_POINT}/${discussion_id}/questions/${question_id}/tasks`)

const getFeedForwards = (discussion_id, question_id) => httpClient.get(`${END_POINT}/${discussion_id}/questions/${question_id}/feedforwards`)
        .then(response => (response?.data?.sort(randomAnonymous), response));


const getTeamSlides = (discussion_id, lang) => {
    let langParam = new URLSearchParams();
    langParam.append("language", lang)

    // above generates url dashboard/REC8gQo89X3QuHttg?tags=dev&tags=female
    return httpClient.get(`${END_POINT}/${discussion_id}/teamslides`, {
        params: langParam,
        timeout: 300000,
        responseType: "arraybuffer"
    });
};

const getTodos = (discussion_id) => httpClient.get(`${END_POINT}/${discussion_id}/todosDone`)

const setTodos = (discussion_id, todos) => httpClient.put(`${END_POINT}/${discussion_id}/todosDone`, todos)

const markDiscussionDone = (discussion_id) => httpClient.put(`${END_POINT}/${discussion_id}/done`)

const markDiscussionOpen = (discussion_id) => httpClient.put(`${END_POINT}/${discussion_id}/open`)

const getDiscussionActions = (discussion_id) => httpClient.get(`${END_POINT}/${discussion_id}/actions`)

export {
    getAllDiscussions,
    getTeamSlides,
    getStatistics,
    getDiscussion,
    getAllQuestions,
    reorderQuestions,
    resetQuestionOrder,
    getTasks,
    getFeedForwards,
    getTodos,
    setTodos,
    markDiscussionDone,
    markDiscussionOpen,
    getDiscussionActions
}
