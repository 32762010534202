export const CALIDA_GROUP_COMPANY_IDS = [
    '57243CAL184771921', // Calida
];

export const LUNGENLIGA_GROUP_COMPANY_IDS = [
    '58353LBB563999838', // Lungenliga Basel
    '81347LuZ357359634', // Lungenliga Zentralschweiz
    '39650LuS746414790', // Lungenliga Solothurn
    '76163LuA818299967', // Lungenliga Aarau
];

export const POST_GROUP_COMPANY_IDS = [
    '77952FLK470061238', // Post Leitung FLK
    '48721PoP165658466', // Post Personal
    '75925pof181912569', // Post Finanzen
    '47808pos255873195', // Post Stab
    '95462pks109762813', // Post Kommunikations services - it has additional customisation for gdpr05 atm
    '92333pop839514885', // Post Specialties
];

export const TX_GROUP_COMPANY_IDS = [
    '15328txg428123915', // TX group
    '22708gol200809573', // TX Goldbach
    // '23213tam223139668', // TX Tamedia  has separate text now See TB-1033
    '91185min134758496', // 20 minuten
];
