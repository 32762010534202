import AuthService from '../services/auth.service';

import jwt_decode from "jwt-decode";

export function isTokenValid(token) {
    if (!token) return false;

    try {
        const decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000;  // convert milliseconds to seconds
        window.l("in isTokenValid, decoded.exp:", decoded?.exp);
        window.l("in isTokenValid, decoded.exp > currentTime - 60:", decoded?.exp > currentTime - 60);

        return decoded.exp > currentTime - 60;
    } catch (error) {
        window.le("Failed to decode JWT:", error);
        return false;
    }
}


const user = JSON.parse(localStorage.getItem('user'));
const initialState = {
    status: { loggedIn: user && isTokenValid(user.accessToken) },
    user: user && isTokenValid(user.accessToken) ? user : null
};

export const auth = {
  namespaced: true,
  state: {
    ...initialState
},
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).catch(
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    urlLogin({ commit }, token) {
        return AuthService.urlLogin(token).catch(
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    relogin({ commit  }) {
        return AuthService.relogin().then(
            user => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return AuthService.logout();
            }
        );
    },
    tokenLogin({ commit }) {
      return AuthService.loginToken().then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
      );
    },
    logout({ commit }) {
      return AuthService.logout();
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = isTokenValid(user.accessToken)
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    setIntroShown(state) {
      console.log("setting local showIntro: false")
      state.user.showIntro = false;
    },
    setGDPRAccepted(state) {
      console.log("setting local gdprAccepted: true")
      state.user.gdprAccepted = true;
    },
    updateName(state, user){
      state.user.firstName = user.firstName;
      state.user.lastName = user.lastName
    },
    updateEmail(state, email){
      state.user.email = email;
    },
    updateLanguage(state, language){
      state.user.language = language;
    },
  },
  getters: {
    companyId(state) {
        if (state.user) {
            return state.user.companyId
        } else {
            return null
        }

    },
    userRoles(state) {
        if (state.user) {
            return state.user.authorities.map(item => item.authority);
        } else {
            return [];
        }
    },
    isRoot(state, getters) {
        if (state.user) {
            return getters.userRoles.includes("ROLE_ROOT");
        } else {
            return false;
        }
    },
    isCompanyAdmin(state, getters) {
        if (state.user) {
            return getters.userRoles.includes("ROLE_SUPERADMIN");
        } else {
            return false;
        }
    },
    isTeamAdmin(state, getters) {
        if (state.user) {
            return getters.userRoles.includes("ROLE_ADMIN");
        } else {
            return false;
        }
    },
    isMember(state, getters) {
        if (state.user) {
            return getters.userRoles.includes("ROLE_MEMBER");
        } else {
            return false;
        }
    },
    isMemberOnly(state, getters) {
        if (state.user) {
            return getters.userRoles.includes("ROLE_MEMBER") && !(getters.userRoles.includes("ROLE_ADMIN") || getters.userRoles.includes("ROLE_SUPERADMIN"));
        } else {
            return false;
        }
    },
    isAdmin(state, getters) {
        if (state.user) {
            return getters.isRoot || getters.isCompanyAdmin || getters.isTeamAdmin;
        } else {
            return false;
        }
    },
    hasPermission: (state, getters) => (permission) => {
          if (state.user) {
              return getters.userRoles.includes(permission);
          } else {
              return false;
          }
      },
}
};
