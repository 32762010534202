var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[(_vm.showSnack)?_c('Snackbar'):_vm._e(),(_vm.logoutMode)?_c('div',{staticClass:"pa-12 mx-auto text-center"},[_c('v-progress-circular',{staticClass:"mx-auto mt-10",attrs:{"indeterminate":"","size":"30","color":"primary"}}),_c('div',{staticClass:"text-caption mt-7"},[_vm._v(_vm._s(_vm.$t("loggingOut")))])],1):_vm._e(),(!_vm.logoutMode && _vm.topNav)?_c('Navbar',{staticClass:"d-print-none"}):_vm._e(),_c('v-main',[(!_vm.logoutMode)?_c('v-container',{staticClass:"noPaddingMobile"},[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":true}},[_c('router-view')],1)],1):_vm._e()],1),_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(
                _vm.fab &&
                _vm.$route.name !== 'login' &&
                _vm.$route.name !== 'select' &&
                _vm.$route.name !== 'question' &&
                !_vm.logoutMode
            ),expression:"\n                fab &&\n                $route.name !== 'login' &&\n                $route.name !== 'select' &&\n                $route.name !== 'question' &&\n                !logoutMode\n            "}],staticClass:"d-print-none",attrs:{"fab":"","fixed":"","bottom":"","right":""},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1),(!_vm.topNav && !_vm.logoutMode)?_c('NavbarBottom',{staticClass:"d-print-none"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }